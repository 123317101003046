<template>
  <apexchart
    v-if="series[0]?.data?.length"
    :height="250"
    type="bar"
    :options="options"
    :series="series"
    @legend-click="legendClick"
  />
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  categories: {
    type: Array,
    default() {
      return []
    },
  },
  values: {
    type: Array,
    default() {
      return []
    },
  },
  labels: {
    type: Array,
    default() {
      return []
    },
  },
  colors: {
    type: Array,
    default() {
      return []
    },
  },
})

const maxY = computed(() => {
  const maxValue = props.values.reduce((prevMax, data) => Math.max(prevMax, data.reduce((prev, el) => Math.max(prev, el), 0)), 0)
  return Math.round(maxValue * 1.20)
})

const series = computed(() => props.values.map((data, indexValue) => ({
  name: props.labels[indexValue],
  fillColor: props.colors?.length ? props.colors[indexValue] : undefined,
  data: data.map((value, index) => ({
    fillColor: props.colors?.length ? props.colors[indexValue] : undefined,
    value,
    y: value,
    x: index + 1,
  })),
})))

const categories = computed(() => {
  const firstValueRow = (props.values || [[]])[0]
  if (props.categories?.length === firstValueRow.length) return props.categories
  return firstValueRow.map((el, index) => String(index + 1))
})

let lastClicked = -1
const legendClick = (chartContext, seriesIndex) => {
  const same = lastClicked === seriesIndex
  series.value.forEach((s, idx) => {
    if (same || idx === seriesIndex) {
      chartContext.showSeries(s.name)
      return
    }
    chartContext.hideSeries(s.name)
  })
  lastClicked = same ? -1 : seriesIndex
}

const options = computed(() => ({
  chart: {
    type: 'bar',
    height: 350,
    zoom: false,
  },
  legend: {
    markers: {
      fillColors: series.value.map(el => el.fillColor).filter(el => !!el),
    },
    onItemClick: {
      toggleDataSeries: false,
    },
  },
  toolbar: {
    tools: {
      download: '<i class="fas fa-save" width="50" height="20" />',
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded',
      borderRadius: 2,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    },
  },
  dataLabels: {
    enabled: true,
    // formatter(val, ctx) {
    //   const { seriesIndex } = ctx
    //   const n = Number(val)
    //   if (seriesIndex === 0) {
    //     return `${parseInt(n, 10)}`
    //   }
    //   if (seriesIndex === 1) {
    //     return `€ ${Number(n).toFixed(2)}`
    //   }
    //   return parseInt(n, 10)
    // },
    offsetY: -20,
    style: {
      fontSize: '8px',
      colors: ['#304758'],
    },
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    type: 'category',
    tickPlacement: 'on',
    categories: categories.value,
    overwriteCategories: categories.value,
    tickAmount: categories.value.length,
    min: categories.value[0],
    max: categories.value[categories.value.length - 1],
  },
  yaxis: {
    enabled: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
    },
    max: maxY.value,
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      // formatter: function(val, ctx) {
      //   const { seriesIndex } = ctx
      //   if (seriesIndex === 0) {
      //     return `${parseInt(Math.round(val), 10)} Ritten`
      //   }
      //   if (seriesIndex === 1) {
      //     return `€ ${Number(val).toFixed(2)}`
      //   }
      //   return parseInt(val, 10)
      // },
    },
  },
}))
</script>
